import { DocumentNode, gql } from 'apollo-boost';

export interface QueryBaseProps {
  id?: string;
  locale: string | string[];
  cookieId?: string;
  globalIds: { cookieId: string; footerId: string };
}

export const head = `
  seo {
    title
    description
    ogType
    ogTitle
    ogDescription
    ogImage {
      url
    }
  }
`;

export const sharedCopies = (locale: QueryBaseProps['locale']) => `
  sharedCopies(locale: "${locale}") {
    global
    buttonsHoverColor
    buttonsHoverDefaultColors
    copiedText
  }
`;

export const navbar = (locale: QueryBaseProps['locale']) => `
  navbar(locale: "${locale}") {
    hamburgerLabelOpen
    hamburgerLabelClose
    navLinks {
      id
      href
      label
      headerText
    }
    searchInputText
    searchNoResultsMessage
    emailTile {
      title
      email
    }
    hoveredText
    homePageNavLinks {
      id
      href
      label
      headerText
    }
    linksHoverColor
    linksHoverDefaultColors
    menuBackgroundColor
    menuBackgroundDefaultColors
  }
`;

export const footer = id => `
  footer(id: "${id}") {
    address
    contact
    locationTextColor
    locationDefaultTextColors
    linksColor
    linksDefaultTextColors
    bottomText
    pageRoutes {
      name
      href
    }
    footer {
        name
        heading
        telephoneNumber
        email
        bottomContactsInfo {
          text
        }
        links {
          label
          href
          isExternal
        }
        copyright
        socialsToastText
        socials {
          title
            type
        }
        socialsLinks {
          title
          url
        }
        timeZone
        coordinates {
          office {
            latitude
            longitude
          }
        }
    }
  }
`;

export const redirectPopup = (locale: QueryBaseProps['locale']) => `
  redirectPopups(locale: "${locale}") {
    redirectContent {
      headingText
      button1Label
      button1Href
      continueButtonLabel
      query
      isVisibleOnLoad
      fixedVisibility
    }
  }
`;

export const cookieBar = id => `
  cookieBar(id: ${id}) {
    buttonTextAccept
    buttonTextReject
    necessaryCookieLabel
    functionalityCookieLabel
    analyticsCookieLabel
    mainCopy
  }
`;

export const globalCopies = (
  id,
  locale: QueryBaseProps['locale'],
  globalIds: QueryBaseProps['globalIds']
  // cookieId?: string,
) => `
  ${cookieBar(globalIds.cookieId)}
  ${redirectPopup(locale)}
  ${sharedCopies(locale)}
  ${navbar(locale)}
  ${footer(globalIds.footerId)}
`;

export const indexPageQuery = ({
  id = '1',
  locale = process.env.DEFAULT_LOCALE,
  globalIds = { cookieId: '1', footerId: '1' },
}: QueryBaseProps): DocumentNode => gql`
  {
    ${globalCopies(id, locale, globalIds)}
    homePage(id: "${id}") {
      ${head}
      modules {
        ... on ComponentSectionsDoubleColorHeadline {
          headlines {
            id
            headline
            duration
            delay
            greyColor
            newLine
            accentColor
            accentDefaultColors
          }
          button {
            href
            label
          }
          heightValue
        }
        ... on ComponentSectionsCarousel {
          carouselSlides {
            media {
              url
              alternativeText
            }
            mediaType
            question
            clientName
            caseStudy {
              slug
            }
            buttonText
            videoErrorMessage
            source
            sourceId
            azurePlaceholder {
              url
            }
            autoplay
            withSound
            manualPlay
            mute
            azureUserId
            vimeoBackground
          }
          duration
        }
        ... on ComponentSectionsVisualCarousel {
          introContent {
            media {
              url
              alternativeText
            }
            mediaType
            videoErrorMessage
            source
            sourceId
            azurePlaceholder {
              url
            }
            autoplay
            withSound
            manualPlay
            mute
            azureUserId
            vimeoBackground
          }
          introSupportingText
          introSupportingButton {
            label
            href
          }
          secondIntroSupportingText
          secondIntroSupportingButton {
            label
            href
          }
          headline
          slides {
            media {
              url
              alternativeText
            }
            mediaType
            question
            clientName
            caseStudy {
              slug
            }
            buttonText
            videoErrorMessage
            source
            sourceId
            azurePlaceholder {
              url
            }
            autoplay
            withSound
            manualPlay
            mute
            azureUserId
            vimeoBackground
          }
          duration
        }
        ... on ComponentSectionsRedirectionFilters {
          headline
          buttonText
          filters {
            filter {
              id
              tag
            }
          }
        }
        ... on ComponentSectionsNumberCards {
          headline
          numberCards {
            id
            title
            subtitle
            titleColor
            titleDefaultColors
          }
          buttonText
        }
        ... on ComponentSectionsImageArticle {
          headline
          articles {
            id
            text
          }
          buttonText
          media {
            url
            alternativeText
          }
        }
      }
    }
  }
`;

export const workPageQuery = ({
  id = '1',
  locale = process.env.DEFAULT_LOCALE,
  globalIds = { cookieId: '1', footerId: '1' },
}: QueryBaseProps): DocumentNode => gql`
  {
    ${globalCopies(id, locale, globalIds)}
    workPage(id: "${id}") {
      ${head}
      headline
      buttonText
      filterPopup {
        filterTagsList {
          text
          tag {
            tag
          }
          all
        }
      }
      projects {
        id
        mediaType
        image {
          url
          alternativeText
        }
        video {
          url
          alternativeText
        }
        aspectRatio
        videoErrorMessage
        autoplay
        mute
        color
        headerText
        headerColoredText
        gridViewTextColor
        defaultBlackHeading
        heading
        description
        tag {
          tag
        }
        tags {
          tag
        }
        redirectTo
        newsArticle {
          slug
        }
        caseStudy {
          slug
        }
        awardPage {
          slug
        }
        eventPage {
          slug
        }
        insightArticle {
          slug
        }
      }
    }
  }
`;

export const contactPageQuery = ({
  id = '1',
  locale = process.env.DEFAULT_LOCALE,
  globalIds = { cookieId: '1', footerId: '1' },
}: QueryBaseProps): DocumentNode => gql`
  {
    ${globalCopies(id, locale, globalIds)}
    contactPage(id: "${id}") {
      ${head}
      contactOfficeCards {
        socialsLabel
        emailTile {
          id
          title
          email
        }
        offices(sort: "order:asc") {
          id
          order
          office {
            id
            heading
            contactLabel
            telephoneNumber
            addressLabel
            addressLine1
            addressButton {
              href
              label
              isExternal
            }
            image {
              url
              alternativeText
            }
            video {
              url
              alternativeText
            }
            mediaType
            latitude
            longitude
            emailFields {
              title
              email
            }
            hoveredMedia
            telephoneNumber2
            addressButton2 {
              href
              label
              isExternal
            }
            addressLine2
            emailFields2 {
              title
              email
            }
            textField
            emails {
              id
              title
              email
            }
            contactText
            introCopy
            expertiseSectionLabel
            expertiseSectionCategories {
              id
              text
            }
            getInTouchSectionLabel
            getInTouchActiveLabel
            getInTouchSectionCategories {
              id
              text
            }
            contactUsLabel
            findUsLabel
            relatedArticlesSectionLabel
            relatedArticles {
              id
              name
              slug
              order
            }
          }
        }
      }
      copyEmailText
      toastText
    }
  }
`;

export const insightsPageQuery = ({
  id = '1',
  locale = process.env.DEFAULT_LOCALE,
  globalIds = { cookieId: '1', footerId: '1' },
}: QueryBaseProps): DocumentNode => gql`
  {
    ${globalCopies(id, locale, globalIds)}
    insightsPage(id: "${id}") {
      ${head}
      name
      header {
        ${coloredHeader}
      }
      title {
        headlines {
          id
          headline
          duration
          delay
          greyColor
          newLine
        }
      }
      buttonText
      filterPopup {
        filterTagsList {
          text
          tag {
            tag
          }
          all
        }
      }
      articles {
        id
        image {
          url
          alternativeText
          formats
        }
        video {
          url
          alternativeText
        }
        mediaType
        aspectRatio
        autoplay
        mute
        videoErrorMessage
        heading
        description
        tag {
          tag
        }
        tags {
          tag
        }
        redirectTo
        newsArticle {
          slug
          writingDate
        }
        caseStudy {
          slug
          writingDate
          basicAuthEnabled
        }
        awardPage {
          slug
          writingDate
        }
        eventPage {
          slug
          writingDate
        }
        insightArticle {
          slug
          writingDate
        }
      }
      cursorLabelRead
    }
  }
`;

export const joinPage = ({
  id = '1',
  locale = process.env.DEFAULT_LOCALE,
  globalIds = { cookieId: '1', footerId: '1' },
}: QueryBaseProps): DocumentNode => gql`
  {
    ${globalCopies(id, locale, globalIds)}
    joinUsPage(id: "${id}") {
      ${head}
      modules {
        ... on ComponentSectionsHeadlineSection {
          headlines {
            id
            headline
            duration
            delay
            greyColor
            newLine
            accentColor
            accentDefaultColors
          }
        }

        ... on ComponentSectionsJoinUsHero {
          mediaSrc {
            url
          }
          middleText
        }

        ... on ComponentSectionsHero {
          mediaType
          media {
            url
          }
        }

        ... on ComponentSubComponentsSection {
          text
          cta {
            label
            href
          }
        }

        ... on ComponentSubComponentsSectionTitle {
          text
        }

        ... on ComponentSectionsValuesSection {
          sectionTitle
          valueCard {
            title
            rteSubtitle
          }
        }

        ... on ComponentSectionsEventsGallerySection {
          sectionTitle
          dragIndicatorLabel
          images {
            image {
              url
            }
          }
        }

        ... on ComponentSubComponentsSectionTitle {
          text
        }

        ... on ComponentSectionsInternship {
          internshipTitle :title
          internshipDescription:description
          ctaLabel
          internshipTypes {
            title
            description
            image {
              url
            }
            titleHoverColor
            titleDefaultHoverColors
          }
        }

        ... on ComponentSubComponentsImageBlock {
          image {
            url
          }

          imageCaption
        }

        ... on ComponentSectionsJobList {
          title
          description
          hoverTextColor
          hoverDefaultTextColors
          cta {
            href
            label
          }
          socials {
            title
            type
          }
        }

        ... on ComponentSectionsFaqSection {
        title
        description
        ctaLabel
        faq {
          question
          answer
        }
      }
      }
      jobOverlayHeading
      jobOverlayFilterLabel
      jobOverlayLocationLabel
      jobOverlayDepartmentLabel
      jobOverlayLocationPlaceholder
      jobOverlayDepartmentPlaceholder
      jobOverlayAppliedFiltersLabel
      jobOverlayResultsLabel
      jobOverlayShareLabel
      jobOverlayTypeLabel
      jobOverlayLevelLabel
      jobOverlayApplyCtaLabel
      FaqFooterCopy
      faqCtaLabel
      jobOverlayJobTitle
    }
  }
`;

export const insightTypeArticle = ({
  id = '1',
  slug,
  locale = process.env.DEFAULT_LOCALE,
  type,
  globalIds = { cookieId: '1', footerId: '1' },
}): DocumentNode => gql`
  {
    ${globalCopies(id, locale, globalIds)}
    ${type} (where: {slug: "${slug}"}, locale: "${locale}") {
      ${head}
      name
      slug
      writingDate
      nextArticleText
      nextArticleTextMobile
      previousArticleText
      previousArticleTextMobile
      headerText
      modules {
        ... on ComponentComponentsQuote {
          id
          quote
          author
          description
          width
          variant
        }

        ... on ComponentComponentsRichText {
          id
          richText
        }

        ... on ComponentSubComponentsVideo {
          id
          video {
            url
          }
          errorMessage
          videoType {
            source
            sourceId
            azurePlaceholder {
              url
            }
            autoplay
            withSound
            manualPlay
            mute
            azureUserId
            vimeoBackground
          }
        }

        ... on ComponentSubComponentsImageBlock {
          id
          image {
            url
          }
        }

        ... on ComponentSectionsArticleHero {
          id
          articleLabel
          date
          heading
          articleAuthor: author
          authorDescription
          image {
            url
          }
        }

        ... on ComponentComponentsArticlesSlider {
          id
          sliderItems {
            media {
              url
            }
            caption
          }
        }

      }
      socials {
        text
        links {
          title
          url
        }
        toastText
        socials {
          title
          type
        }
      }
    }
    articles: ${type} (sort: "writingDate:ASC", locale: "${locale}") {
      name,
      slug
    }
  }
`;

export const accessibilityPage = ({
  id = '1',
  locale = process.env.DEFAULT_LOCALE,
  globalIds = { cookieId: '1', footerId: '1' },
}): DocumentNode => gql`
  {
    ${globalCopies(id, locale, globalIds)}
    accessibilityPage (id: "${id}") {
      ${head}
      heading
      subheading
      accessibilityTextBlock {
        heading
        subheading
      }
    }
  }
`;

export const privacyPage = ({
  id = '1',
  locale = process.env.DEFAULT_LOCALE,
  globalIds = { cookieId: '1', footerId: '1' },
}): DocumentNode => gql`
  {
    ${globalCopies(id, locale, globalIds)}
    privacyPage (id: "${id}") {
      ${head}
      title
      description
      modules {
        ... on ComponentSectionsIndentedTextSection {
          indentedText {
            title
            richText
          }
        }

        ... on ComponentSectionsPrivacyTextBlockSection {
          privacyTextBlock {
            title
            richText
          }
        }

        ... on ComponentSectionsTableSection {
          title
          columnNames {
            column1
            column2
            column3
            column4
          }
          rows {
            column1
            column2
            column3
            column4
          }
        }
      }
    }
  }
`;

export const cookiesPage = ({
  id = '1',
  locale = process.env.DEFAULT_LOCALE,
  globalIds = { cookieId: '1', footerId: '1' },
}): DocumentNode => gql`
  {
    ${globalCopies(id, locale, globalIds)}
    cookiesPage (id: "${id}") {
      ${head}
      title
      description
      cookiesSettingsButton
      overlayAcceptCookiesButton
      modules {
        ... on ComponentSectionsIndentedTextSection {
          indentedText {
            title
            richText
          }
        }

        ... on ComponentSectionsPrivacyTextBlockSection {
          privacyTextBlock {
            title
            richText
          }
        }

        ... on ComponentSectionsTableSection {
          title
          columnNames {
            column1
            column2
            column3
            column4
          }
          rows {
            column1
            column2
            column3
            column4
          }
        }
      }
    }
  }
`;

export const playPageQuery = ({
  id = '1',
  locale = process.env.DEFAULT_LOCALE,
  globalIds = { cookieId: '1', footerId: '1' },
}: QueryBaseProps): DocumentNode => gql`
  {
    ${globalCopies(id, locale, globalIds)}
    darkSideContent(id: "${id}") {
      darkSideItems {
        mediaSrc {
          url
        }
        type
        itemHref
        isHrefExternal
        dontOpenModal
        description
        buttonLabel
      }
      ${head}
    }
  }
`;

export const notFoundPageQuery = ({
  locale = process.env.DEFAULT_LOCALE,
}: QueryBaseProps): DocumentNode => gql`
  {
    sharedCopy(locale: "${locale}"){
      data {
        attributes {
          global
        }
      }
    }
    notFoundPage(locale: "${locale}") {
      data {
        attributes {
          ${head}
        }
      }
    }
  }
`;

export const aboutPageQuery = ({
  id = '1',
  locale = process.env.DEFAULT_LOCALE,
  globalIds = { cookieId: '1', footerId: '1' },
}: QueryBaseProps): DocumentNode => gql`
    {
      ${globalCopies(id, locale, globalIds)}
        awards(locale: "${locale}") {
          name
          year
          organisation
          project
          featured
          featuredOrder
        }
        aboutPage(id: "${id}") {
          ${head}
            modules {
                ... on ComponentSectionsHero {
                  id
                  media {
                    mime
                    url
                    formats
                    alternativeText
                  }
                  shortVideo {
                    url
                    alternativeText
                  }
                  mediaType
                  videoType {
                    source
                    sourceId
                    azureUserId
                    azurePlaceholder {
                      url
                      alternativeText
                    }
                    autoplay
                    manualPlay
                    mute
                    withSound
                    vimeoBackground
                  }
                  videoErrorMessage
                  bottomHeading
                  cursorLabelPlay
                  cursorLabelClose
                }
                ... on ComponentSectionsHeadlineSection {
                    headlines {
                      id
                      headline
                      duration
                      delay
                      greyColor
                      newLine
                      accentColor
                      accentDefaultColors
                    }
                }
                ... on ComponentSectionsInfoBoxesSection {
                    id
                    numberCards {
                      id
                      title
                      subtitle
                    }
                    optionalButton {
                      href
                      label
                    }
                }
                ... on ComponentSectionsLeadershipSection {
                  animatedHeader
                  sectionTitle
                  description
                  linkedInLabel
                  cards {
                    id
                    title
                    subtitle
                    description
                    linkedInURL
                    email
                    image{
                      url
                      formats
                      alternativeText
                    }
                    overlay{
                      url
                      formats
                      alternativeText
                    }
                  }
                }
                ... on ComponentSectionsSocialMediaSection {
                  title
                  socialMediaCards {
                    id
                    handle
                    socialMediaType
                    date
                    headline
                    subCopy
                    postUrl
                    image {
                      url
                      alternativeText
                    }
                  }
                }
                ... on ComponentSectionsTextContentSection {
                  title
                  mainTextContent
                }
                ... on ComponentSectionsDoubleColumnTextSection {
                  title
                  leftSideTextContent
                  rightSideTextContent
                }
                ... on ComponentSectionsClientsSection {
                  id
                  sectionTitle
                  mainCategory
                  mainCategoryTextColor
                  mainCategoryDefaultTextColors
                  industries{
                    id
                    industry
                    textColor
                    defaultTextColors
                   }
                  clients(sort: "name:ASC"){
                    id
                    name
                    textColor
                    defaultTextColors
                    image{
                      mime
                      url
                      formats
                      alternativeText
                    }
                    case_study_page {
                      slug
                    }
                    industries{
                      id
                      industry
                    }
                  }
                }
                ... on ComponentSectionsAwardsSection {
                  id
                  heading
                  projectLabel
                  sectionTitle
                  organisationLabel
                  awardLabel
                  dropdownLabel
                  button
                  awardItem {
                    id
                    title
                    awardCategories
                    middleTopContent
                    middleBottomContent
                    relatedArticle {
                      id
                      name
                      tag
                      heading
                      description
                      nextArticleText
                      slug
                      writingDate
                    }
                    icon {
                      url
                      alternativeText
                    }
                  }
                }
                ... on ComponentSectionsExpertiseSection{
                  id
                  sectionTitle
                  categories(sort: "showAs:ASC"){
                    expertiseCategory{
                      categoryTitle
                      categoryTitleColor
                      categoryTitleDefaultColors
                      listItems{
                        label
                        labelColor
                        labelDefaultColors
                        overlaySectionTitle
                        overlayTitle
                        overlayRichText
                        caseStudies {
                          case_study_page {
                            slug
                          }
                          id
                          title
                          image {
                            url
                            alternativeText
                          }
                        }
                        insights {
                          news_article {
                            slug
                            modules {
                              ... on ComponentSectionsArticleHero {
                                image {
                                  url
                                  alternativeText
                                }
                              }
                            }
                          }
                          insights_article {
                            slug
                            modules {
                              ... on ComponentSectionsArticleHero {
                                image {
                                  url
                                  alternativeText
                                }
                              }
                            }
                          }
                          id
                          title
                          subtitle
                        }
                        filter {
                          tag
                        }
                      }
                      relatedCaseStudies{
                        sectionTitle
                        cursorLabelView
                      }
                      relatedInsights {
                        sectionTitle
                      }
                    }
                  }
                }
                ... on ComponentSectionsCapabilitiesSection {
                  id
                  title
                  description
                  duration
                  carouselSlides {
                    media {
                      url
                      alternativeText
                    }
                    mediaType
                    question
                    clientName
                    caseStudy {
                      slug
                    }
                    buttonText
                    videoErrorMessage
                    source
                    sourceId
                    azurePlaceholder {
                      url
                    }
                    autoplay
                    withSound
                    manualPlay
                    mute
                    azureUserId
                    vimeoBackground
                  }
                  filtersLabel
                  badge {
                      title
                      url
                      id
                      expertise_subcategory_index
                      expertise_category {
                        expertiseCategory {
                          listItems {
                            label
                            overlayTitle
                            overlayRichText
                            caseStudies {
                              case_study_page {
                                slug
                              }
                              id
                              title
                              image {
                                url
                                alternativeText
                              }
                            }
                          }
                          relatedCaseStudies{
                            sectionTitle
                            cursorLabelView
                          }
                          relatedInsights {
                            sectionTitle
                          }
                        }
                      }
                  }
                }
                ... on ComponentSectionsOfficesCarouselSection {
                  id
                  sectionTitle
                  offices(sort: "order:asc") {
                    office{
                      heading
                      addressLabel
                      contactLabel
                      telephoneNumber
                      textField
                      addressLine1
                      addressLine2
                      emailFields {
                        email
                        title
                      }
                      addressButton{
                        href
                        label
                        isExternal
                      }
                      image{
                        mime
                        url
                        formats
                        alternativeText
                      }
                      latitude
                      longitude
                    }
                  }
                }
            }
        }
    }
`;

export const coloredHeader = `
    headerText
    color
    headerColoredText
`;
